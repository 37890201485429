var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("标签管理")]),
      _c(
        "el-row",
        { staticClass: "table" },
        [
          _c(
            "el-row",
            { staticClass: "row-bg" },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "info", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.addTagGroup()
                    },
                  },
                },
                [_vm._v("新增标签组")]
              ),
            ],
            1
          ),
          _c("el-tree", {
            attrs: {
              data: _vm.tagTreeData,
              "node-key": "id",
              "default-expand-all": "",
              "expand-on-click-node": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "span",
                    { staticClass: "custom-tree-node" },
                    [
                      _c("span", { staticClass: "tree-title" }, [
                        _vm._v(_vm._s(data.title)),
                      ]),
                      !data.color
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editTag(data)
                                  },
                                },
                              },
                              [_vm._v("编 辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delTag(data)
                                  },
                                },
                              },
                              [_vm._v("删 除")]
                            ),
                          ]
                        : [
                            _c("span", [
                              _c("i", {
                                staticClass: "el-icon-edit ico-edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.editTag(data)
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "el-icon-delete ico-dele",
                                on: {
                                  click: function ($event) {
                                    return _vm.delTag(data)
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "el-icon-circle-plus",
                                on: {
                                  click: function ($event) {
                                    return _vm.addTag(data)
                                  },
                                },
                              }),
                            ]),
                          ],
                    ],
                    2
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("tag-group", {
        attrs: {
          pageFlag: _vm.tagManage,
          show: _vm.groupDialog,
          groupInfo: _vm.groupInfo,
        },
        on: {
          close: function ($event) {
            _vm.groupDialog = false
            _vm.groupInfo = null
          },
          updateView: _vm.getTagGroupList,
        },
      }),
      _c("add-tag", {
        attrs: {
          pageFlag: _vm.tagManage,
          show: _vm.tagDialog,
          tagInfo: _vm.tagInfo,
          groupId: _vm.groupId,
        },
        on: {
          close: function ($event) {
            _vm.tagDialog = false
            _vm.tagInfo = null
          },
          updateView: _vm.getTagGroupList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }